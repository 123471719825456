<template>
  <div class="c-my-machines">
    <div class="c-my-machines__head">
      <h4><span class="u-text-primary">4</span> tokens resterend</h4>
      <button class="c-button">
        Koop tokens
      </button>
    </div>
    <p>
      Lorem ipsum dolor sit, amet consectetur adipisicing elit. <br>
      Repellat aliquam voluptas doloremque incidunt. Deserunt dignissimos fugiat natus quia iusto
      earum laboriosam, reprehenderit nobis obcaecati modi dolorum magnam, eaque, veritatis unde.
    </p>
    <div class="c-my-machines__search">
      <InputComponent
        v-model="address"
        input-type="text"
        label="Claim automaat"
      />
    </div>

    <div class="c-my-machines__machines">
      <h4>Jouw automaten (9)</h4>
      <MachineTable
        v-if="machines.length > 0"
        :headers="tableHeaders"
        :data="tableData"
      />
    </div>
  </div>
</template>

<script src="./MyMachines.controller.js"></script>
<style lang="scss" src="./MyMachines.styles.scss"></style>
