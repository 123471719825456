import { InputComponent, MachineTable } from "@/components";
import { mapGetters } from "vuex";

export default {
	name: "MyMachines",
	components: {
		InputComponent,
		MachineTable,
	},
	data: () => ({
		address: "",
		tableHeaders: [
			{
				name: "Naam",
			},
			{
				name: "Adres",
			},
			{
				name: "Premium tot",
			},
			{
				name: "Categorieën",
			},
			{
				name: "",
			},
		],
		// tableData: [
		//   {
		//     name: "Broodautomaat Van Hoecke",
		//     address: "herfstlaan 9 \n Deinze",
		//     premiumUntil: Date(),
		//     categories: [
		//       {
		//         category_id: 1,
		//         name: "Aardbeien",
		//         backgroundColor: "#F6CACA",
		//         color: "#DF3D3C"
		//       },
		//       {
		//         category_id: 2,
		//         name: "Brood",
		//         backgroundColor: "#F9E6CB",
		//         color: "#FFB03A"
		//       }
		//     ]
		//   },
		//   {
		//     name: "Broodautomaat Van Den Berge",
		//     address: "Den Duiver 23 \n De Pinte",
		//     premiumUntil: Date(),
		//     categories: [
		//       {
		//         id: 1,
		//         name: "Aardbeien",
		//         backgroundColor: "#F6CACA",
		//         color: "#DF3D3C"
		//       },
		//       {
		//         id: 2,
		//         name: "Verse maaltijden",
		//         backgroundColor: "#DEF5D1",
		//         color: "#7DD747"
		//       }
		//     ]
		//   }
		// ]
	}),
	computed: {
		...mapGetters("machine", {
			machines: "getMachines",
			categories: "getCategories",
		}),
		tableData() {
			return this.machines;
		},
	},
};
